.favorites {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(173,84,139);
  background: linear-gradient(94deg, rgba(173,84,139,1) 0%, rgba(63,20,86,1) 100%);
}

.favorites .container {
  padding: 10px;
  background: rgb(58,43,56);
  background: linear-gradient(94deg, rgba(58,43,56,1) 0%, rgba(37,31,47,1) 100%);
  -webkit-box-shadow: 0px 0px 0px 3px rgba(115,55,104,1);
  -moz-box-shadow: 0px 0px 0px 3px rgba(115,55,104,1);
  box-shadow: 0px 0px 0px 3px rgba(115,55,104,1);
  min-height: 60vh;
}
@media only screen and (max-width: 1440px) {
  .favorites {
    height: auto;
  }

  .favorites .container {
    width: 90%;
  }
}
