.next-launch {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(79,205,196);
  background: linear-gradient(94deg, rgba(79,205,196,1) 0%, rgba(86,99,113,1) 100%);
}

.next-launch .container {
  padding: 50px 0px;
  background: rgb(34,67,68);
  background: linear-gradient(94deg, rgba(34,67,68,1) 0%, rgba(37,48,53,1) 100%);
  -webkit-box-shadow: 0px 0px 0px 3px rgba(68,116,122,1);
  -moz-box-shadow: 0px 0px 0px 3px rgba(68,116,122,1);
  box-shadow: 0px 0px 0px 3px rgba(68,116,122,1);
}

.next-launch h1 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
  font-size: 60px;
  font-weight: 400;
}

.next-launch h3 {
  color: #ffffff;
  font-weight: 400;
  padding-right: 20px;
}



.days, .hours, .minutes, .seconds {
  text-align: center;
  color: #ffffff;
}
.days h2, .hours h2, .minutes h2, .seconds h2 {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
  font-size: 120px;
}
.days p, .hours p, .minutes p, .seconds p {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
  font-size: 50px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 0px 10px;
}
.text-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px;
}
.facebook-share {
  margin-right: 15px;
}

@media only screen and (max-width: 1440px) {
  .next-launch {
    height: auto;
  }
}
