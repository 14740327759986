@import 'components/next-launch/next-launch.css';
@import 'components/upcoming-launches/upcoming-launches.css';
@import 'components/favorites/favorites.css';

@import '_common/loaders/loader.css';

/*  Remove white space on top of screen */
* {
  margin: 0;
  box-sizing: border-box;
}
.container {
  width: 70%;

}
.container {
  width: 70%;
}
.error-message {
  height: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  color: #ffffff;
  font-size: 60px;
}

.arrow {
  border: solid #ffffff;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 20px;
}
.arrow:hover {
  cursor: pointer;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
