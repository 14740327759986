.upcoming-launches {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(173,84,139);
  background: linear-gradient(94deg, rgba(173,84,139,1) 0%, rgba(63,20,86,1) 100%);
}

.upcoming-launches .container {
  padding: 10px;
  background: rgb(58,43,56);
  background: linear-gradient(94deg, rgba(58,43,56,1) 0%, rgba(37,31,47,1) 100%);
  -webkit-box-shadow: 0px 0px 0px 3px rgba(115,55,104,1);
  -moz-box-shadow: 0px 0px 0px 3px rgba(115,55,104,1);
  box-shadow: 0px 0px 0px 3px rgba(115,55,104,1);
  min-height: 60vh;
  overflow: auto;
}

.upcoming-launches h1 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
  font-size: 60px;
  font-weight: 400;
}
.upcoming-launches table {
  /*margin: 10px;*/
  width: 100%;
  border-collapse: collapse;
  color: #ffffff;
  font-size: 20px;
  overflow: auto;
}
.upcoming-launches table tr {
  border-bottom: 1px solid #ffffff;
}
.upcoming-launches table td {
  padding: 5px 0;
  min-width: 200px;
}
.upcoming-launches table th {
  text-align: left;
}
@media only screen and (max-width: 1440px) {
  .upcoming-launches {
    height: auto;
  }

  .upcoming-launches .container {
    width: 90%;
  }
}
